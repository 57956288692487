<template>
  <v-container fluid class="tw-p-6 tw-pt-20">
    <div
      class="tw-flex tw-font-semibold tw-justify-between tw-items-center tw-text-3xl tw-mb-4"
    >
      <h1 class="tw-text-2xl">Preventivi Scatole</h1>
      <NewBoxQuotationDialog
        @onCreate="onQuotationCreated"
      />
    </div>
    <v-data-table
      :headers="headers"
      :items="boxQuotations"
      :loading="loading"
      :options.sync="pagination"
      :server-items-length="totalItems"
      class="elevation-1"
      loading-text="Sto caricando..."
      @click:row="onRowClick"
      item-class="pointer"
    >
     <template v-slot:item.status="{ item }">
        <v-chip>
          {{ _mapBoxQuotationStatus(item.status) }}
        </v-chip>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:item.last_update="{ item }">
        {{ formatDate(item.last_update) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "moment/locale/it";
import NewBoxQuotationDialog from "../components/BoxQuotations/NewBoxQuotationDialog.vue";
import { mapBoxQuotationStatus } from "../plugins/utils";


export default {
  components: {
    NewBoxQuotationDialog,
  },
  data() {
    return {
      boxQuotations: [],
      totalItems: 0,
      loading: false,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      headers: [
        { text: "Cliente", value: "customer.name" },
        { text: "Riferimento", value: "name" },
        { text: "Fornitore", value: "machine_supplier.username" },
        { text: "Stato", value: "status" },
        { text: "Creato il", value: "created_at" },
        { text: "Ultimo aggiornamento", value: "last_update" },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getBoxQuotations();
      },
      deep: true,
    },
  },
  methods: {
    _mapBoxQuotationStatus(status) {
      return mapBoxQuotationStatus(status);
    },
    async onRowClick(item) {
      this.$router.push(`/box-quotation/${item.id}`);
    },
    async getBoxQuotations() {
      this.loading = true;
      try {
        const response = await axios.get("box-quotation", {
          params: {
            page: this.pagination.page,
            per_page: this.pagination.itemsPerPage,
          },
        });

        console.log(response.data.items);

        this.boxQuotations = response.data.items;
        this.totalItems = response.data.total;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(dateString) {
      return moment(dateString).locale("it").format("DD/MM/YYYY HH:mm");
    },
    onQuotationCreated() {
      this.getBoxQuotations();
    },
  },
  mounted() {
    moment.locale("it"); // Imposta lingua italiana
    this.getBoxQuotations();
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer !important;
}