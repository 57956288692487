<template>
  <div>
    <v-dialog v-model="dialog" max-width="800" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          dark class="!tw-bg-custom-blue tw-ml-4"
          color="primary" 
          v-bind="attrs"
          v-on="on"
          :disabled="!canCreateRevision"
        >
          <v-icon left>mdi-plus</v-icon>
          Nuova Revisione
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline">
          Crea Nuova Revisione
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formData.dimensions"
                  label="Dimensioni"
                  required
                  :rules="[v => !!v || 'Campo obbligatorio']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model.number="formData.quantity"
                  label="Quantità"
                  type="number"
                  min="1"
                  required
                  :rules="[v => !!v || 'Campo obbligatorio']"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="formData.composition"
                  label="Composizione"
                  required
                  :rules="[v => !!v || 'Campo obbligatorio']"
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="formData.press"
                  label="Pressa"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="formData.notes"
                  label="Note"
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  v-model="newAttachments"
                  label="Allegati"
                  multiple
                  chips
                  show-size
                  @change="handleFileUpload"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="grey darken-1" 
            text 
            @click="dialog = false"
          >
            Annulla
          </v-btn>
          <v-btn 
            color="primary" 
            @click="saveRevision"
            :loading="loading"
          >
            Salva Revisione
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    quotationId: {
      type: [Number, String],
      required: true
    },
    currentData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      dialog: false,
      loading: false,
      formData: {
        dimensions: '',
        composition: '',
        quantity: 1,
        press: '',
        notes: '',
        attachments: []
      },
      newAttachments: []
    };
  },

  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    
    canCreateRevision() {
      return ['commerciale', 'master'].includes(this.user?.role);
    }
  },

  watch: {
    currentData: {
      immediate: true,
      handler(newVal) {
        // Popola il form con i dati correnti
        this.formData = {
          dimensions: newVal.dimensions || '',
          composition: newVal.composition || '',
          quantity: newVal.quantity || 1,
          press: newVal.press || '',
          notes: newVal.notes || '',
          attachments: newVal.attachments || []
        };
      }
    }
  },

  methods: {
    async handleFileUpload(files) {
      this.formData.attachments = await Promise.all(
        Array.from(files).map(file => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve({
              name: file.name,
              content: reader.result.split(',')[1],
              type: file.type
            });
            reader.readAsDataURL(file);
          });
        })
      );
    },

    async saveRevision() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      
      try {
        const payload = {
          ...this.formData,
          attachments: [
            ...(this.currentData.attachments || []),
            ...this.formData.attachments
          ]
        };

        await this.$axios.patch(`/box-quotation/${this.quotationId}`, payload);
        
        this.$store.dispatch('showSnackbar', {
          text: 'Revisione creata con successo',
          type: 'success'
        });
        
        this.dialog = false;
        this.$emit('revision-created');
      } catch (error) {
        this.$store.dispatch('showSnackbar', {
          text: error.response?.data?.msg?.text || 'Errore durante il salvataggio',
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>