<template>
  <v-container v-if="quotation.id" fluid class="tw-p-6 tw-pt-20">
    <v-row>
      <!-- Left Panel -->
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>
            <div
              class="tw-flex tw-font-semibold tw-justify-between tw-items-center tw-text-3xl"
            >
              <h1 class="tw-text-2xl tw-mr-4">Preventivo Scatola</h1>
            </div>
            <v-spacer></v-spacer>
            {{ headline }}
            <v-chip class="ml-4" :color="statusColor" dark>{{
              statusLabel
            }}</v-chip>
          </v-card-title>

          <v-divider></v-divider>
          

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h3 class="tw-mb-2">Riferimento</h3>
                <v-icon left>mdi-file-document</v-icon>
                {{ quotation.name }}
              </v-col>
              <v-col cols="12" md="6">
                <h3 class="tw-mb-2">Cliente</h3>
                <div>{{ quotation.customer.name }}</div>
              </v-col>
              <v-col cols="12" md="6">
                <h3 class="tw-mb-2">Fornitore</h3>
                <div>{{ quotation.machine_supplier.username }}</div>
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col cols="12">
                <h3>Dettagli Preventivo</h3>
                <v-text-field
                  v-model="formData.dimensions"
                  label="Dimensioni"
                  :readonly="!isEditing"
                  :disabled="!isEditing"
                ></v-text-field>

                <v-textarea
                  v-model="formData.composition"
                  label="Composizione"
                  :readonly="!isEditing"
                  :disabled="!isEditing"
                ></v-textarea>

                <v-text-field
                  v-model.number="formData.quantity"
                  label="Quantità"
                  type="number"
                  :readonly="!isEditing"
                  :disabled="!isEditing"
                ></v-text-field>

                <v-text-field
                  v-model="formData.press"
                  label="Pressa"
                  :readonly="!isEditing"
                  :disabled="!isEditing"
                ></v-text-field>

                <v-file-input
                  v-if="isEditing"
                  v-model="newAttachments"
                  label="Nuovi allegati"
                  multiple
                  chips
                  show-size
                  @change="handleFileUpload"
                ></v-file-input>

                <div v-else>
                  <h4>Allegati</h4>
                  <div v-for="file in currentAttachments" :key="file.name">
                    {{ file.name }}
                    <v-btn icon small @click="downloadFile(file)">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </div>
                </div>

                <v-col cols="12">
                  <h3 class="tw-mb-2">Allegati</h3>
                  <div v-if="currentAttachments.length > 0" class="tw-space-y-2">
                    <div v-for="(file, index) in currentAttachments" :key="index" class="tw-flex tw-items-center">
                      <v-icon class="tw-mr-2">mdi-file-outline</v-icon>
                      <a 
                        :href="fileDownloadUrl(file.path)" 
                        target="_blank"
                        class="tw-text-blue-600 hover:tw-underline tw-flex tw-items-center"
                      >
                        {{ fileName(file.path) }}
                        <v-icon small class="tw-ml-1">mdi-open-in-new</v-icon>
                      </a>
                    </div>
                  </div>
                  <div v-else class="tw-text-gray-500">
                    Nessun allegato presente
                  </div>
                </v-col>
                              
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              v-if="quotation.status !== 'closed'"
              class="!tw-text-custom-blue"
              color="primary"
              @click="toggleEdit"
              :disabled="!canEdit"
            >
              {{ isEditing ? "Annulla" : "Modifica" }}
            </v-btn>

            <v-btn
              dark
              class="!tw-bg-custom-blue"
              v-if="isEditing && hasChanges"
              color="success"
              @click="saveChanges"
              :loading="loading"
            >
              Salva Modifiche (Nuova Revisione)
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              dark
              v-if="
                canChangeStatus && !hasChanges && quotation.status !== 'closed'
              "
              color="primary"
              @click="advanceStatus"
              :loading="loading"
              class="!tw-bg-custom-blue"
            >
              {{ getStatusButtonTitle(quotation.status) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Right Panel -->
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title
            >Revisioni

            <!-- <CreateRevisionModal
              :quotation-id="quotationId"
              :current-data="currentAudit"
              @revision-created="fetchQuotation"
            /> -->
          </v-card-title>

          <v-tabs vertical>
            <v-tab
              v-for="audit in audits"
              :key="audit.id"
              @click="selectAudit(audit)"
            >
              Revisione {{ audit.version }}
              <v-icon right>{{
                currentAudit.id === audit.id ? "mdi-check" : ""
              }}</v-icon>
              <div class="ml-2">{{ formatDate(audit.created_at) }}</div>
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
moment.locale("it");
import CreateRevisionModal from "@/components/BoxQuotations/CreateRevisionModal";

export default {
  components: {},
  data() {
    return {
      quotationId: this.$route.params.id,
      loading: false,
      isEditing: false,
      quotation: {},
      audits: [],
      currentAudit: {},
      formData: {
        dimensions: "",
        composition: "",
        quantity: 1,
        press: "",
        notes: "",
      },
      newAttachments: [],
      customerName: "",
      supplierName: "",
    };
  },

  computed: {
    fileDownloadUrl(filePath) {
      return `/uploads/${filePath}`;
    },

    fileName(fullPath) {
      return fullPath.split('/').pop();
    },
    currentAttachments() {
      try {
        const attachments = this.currentAudit.attachments 
          ? JSON.parse(this.currentAudit.attachments)
          : [];
        return attachments.map(path => ({
          path: path,
          name: this.fileName(path)
        }));
      } catch (error) {
        return [];
      }
    },
    headline() {
      return this?.currentAudit?.id === this.audits[0]?.id
        ? "Ultima Revisione"
        : `Revisione ${this?.currentAudit?.version}`;
    },
    hasChanges() {
      return (
        this.formData.dimensions !== this.currentAudit.dimensions ||
        this.formData.composition !== this.currentAudit.composition ||
        this.formData.quantity !== this.currentAudit.quantity ||
        this.formData.press !== this.currentAudit.press ||
        this.formData.notes !== this.currentAudit.notes ||
        this.newAttachments.length > 0
      );
    },
    canChangeStatus() {
      return ["master", "fornitore", "commerciale"].includes(this.user?.role);
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    statusLabel() {
      return this.quotation.status
        ? this.quotation.status.charAt(0).toUpperCase() +
            this.quotation.status.slice(1)
        : "";
    },

    statusColor() {
      switch (this.quotation.status) {
        case "pending":
          return "warning";
        case "approved":
          return "success";
        case "rejected":
          return "error";
        default:
          return "grey";
      }
    },

    canEdit() {
      // Implementa logica di autorizzazione
      return ["commerciale", "master"].includes(this.user.role);
    },
  },

  async created() {
    await this.fetchQuotation();
  },

  methods: {
    async advanceStatus() {
      try {
        const nextStatus = this.getNextStatus(this.quotation.status);

        // Richiesta di conferma
        const confirm = await this.$confirm(
          `Sei sicuro di voler avanzare lo stato a "${this.getStatusLabel(
            nextStatus
          )}"?`,
          {
            title: "Conferma Avanzamento Stato",
            buttonTrueText: "Avanti",
            buttonFalseText: "Annulla",
          }
        );

        if (!confirm) {
          return; // Annulla se l'utente non conferma
        }

        const response = await this.axios.patch(
          `/box-quotation/${this.quotationId}`,
          { status: nextStatus }
        );

        this.$store.dispatch("notificate", {
          text: `Stato avanzato con successo a "${this.getStatusLabel(
            nextStatus
          )}"`,
          type: "success",
        });

        await this.fetchQuotation();
      } catch (error) {
        this.$store.dispatch("notificate", {
          text: error.response?.data?.msg?.text || "Errore nell'aggiornamento",
          type: "error",
        });
      }
    },

    getNextStatus(currentStatus) {
      const statusFlow = {
        pending: "sent",
        sent: "closed",
      };
      return statusFlow[currentStatus] || currentStatus;
    },

    getStatusButtonTitle(status) {
      switch (status) {
        case "pending":
          return "Invia Preventivo";
        case "sent":
          return "Chiudi Preventivo";
        case "closed":
          return "Avanza Stato";
        case "refused":
          return "Riapri Preventivo";
        default:
          return "Avanza Stato";
      }
    },

    getStatusLabel(status) {
      return status.charAt(0).toUpperCase() + status.slice(1);
    },
    async fetchQuotation() {
      try {
        const res = await this.axios.get(`/box-quotation/${this.quotationId}`);
        this.quotation = res.data.quotation;
        this.audits = res.data.audits;
        this.currentAudit = this.audits[0];
        this.populateFormData();
      } catch (error) {
        this.$store.dispatch("notificate", {
          text: "Errore durante il caricamento",
          type: "error",
        });
      }
    },

    populateFormData() {
      this.formData = {
        dimensions: this.currentAudit.dimensions,
        composition: this.currentAudit.composition,
        quantity: this.currentAudit.quantity,
        press: this.currentAudit.press,
        notes: this.currentAudit.notes,
      };
    },

    selectAudit(audit) {
      this.currentAudit = audit;
      this.populateFormData();
      this.isEditing = false;
    },

    async toggleEdit() {
      if (this.isEditing && this.hasChanges) {
        const confirm = await this.$confirm(
          "Hai modifiche non salvate. Sei sicuro di voler annullare?",
          {
            title: "Conferma Annullamento",
            buttonTrueText: "Annulla Modifiche",
            buttonFalseText: "Continua Modifica",
          }
        );

        if (!confirm) {
          return; // Continua la modifica se l'utente annulla
        }
      }

      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.populateFormData();
      }
    },

    async saveChanges() {
      try {
        const formData = new FormData();
        const jsonData = {
          dimensions: this.formData.dimensions,
          composition: this.formData.composition,
          quantity: this.formData.quantity,
          press: this.formData.press,
          notes: this.formData.notes,
        };

        formData.append('json_data', JSON.stringify(jsonData));

        // Aggiungi i nuovi file
        this.newAttachments.forEach(file => {
          formData.append('attachments', file);
        });

        const response = await this.axios.patch(
          `/box-quotation/${this.quotationId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        );

        // Reset dei file dopo il salvataggio
        this.newAttachments = [];
        
        this.$store.dispatch("notificate", {
          text: "Modifiche salvate con successo! Nuova revisione creata.",
          type: "success",
        });

        this.isEditing = false;
        await this.fetchQuotation();
      } catch (error) {
        // Gestione errori
      }
    },


    formatDate(dateString) {
      return moment(dateString).locale("it").format("DD/MM/YYYY HH:mm");
    },

    handleFileUpload(files) {
      this.newAttachments = [...files];
    },
  },
};
</script>