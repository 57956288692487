import store from '../store'

export const checkForm = (classname) => {
  var forms = document.getElementsByClassName(classname);
  for (var i = 0; i < forms.length; i++) {
    if (!forms[i].reportValidity()) {
      return false;
    }
  }
  return true;

}

export const mapPriorityStatus = (status) => {
  switch (status) {
    case 0:
      return "scaduto"
    case 1:
      return "ordine in corso"
    case 2:
      return "urgente"
    case 3:
      return "standard"
    case 4:
      return "non urgente"
  }
}


export const mapPriorityStatusReverse = (label) => {
  switch (label) {

    case "scaduto":
      return 0;
    case "ordine in corso":
      return 1;
    case "urgente":
      return 2;
    case "standard":
      return 3;
    case "non urgente":
      return 4;
    default:
      return -1; // Return -1 or handle the unknown label case as per your requirements
  }
};


export const mapQuotationStatus = (supplier, type = '') => {
  const userRole = store.state.currentUser.role;  // Ruolo dell'utente attuale
  let status = supplier.status;  // Stato del supplier

  let label = "Stato sconosciuto";  // Etichetta di default
  let color = "#FF0000";  // Colore di default (rosso per errori o stato sconosciuto)

  switch (status) {
    case 0:
      label = "In attesa di invio";
      color = "#E0E0E0";  // Colore grigio
      break;
    case 1:
      label = userRole === 'supplier' ? "Completa preventivo" : "In attesa del prezzo";
      color = userRole === 'supplier' ? "red" : "#ffb400";  // Colore rosso per supplier, arancione per altri
      break;
    case 2:
      if (supplier?.defFiles?.length > 0 && supplier?.dxfFiles?.length === 0 && type === 'preventivi_fustella') {
        // Se mancano i DXF files per il preventivo fustella
        label = "Carica File tecnici";
        color = userRole === 'supplier' ? "#ffb400" : "red";  // Arancione per supplier, rosso per altri
      } else {
        // Gestione per conferma ordine
        label = userRole === 'supplier' ? "In attesa conferma ordine" : "Conferma ordine";
        color = userRole === 'supplier' ? "#ffb400" : "red";  // Giallo per supplier, rosso per altri
      }
      break;
    case 3:
      label = userRole === 'supplier' ? "Inserisci bozza" : "Caricamento bozza in corso";
      color = userRole === 'supplier' ? "red" : "#ffb400";  // Colori per bozza
      break;
    case 4:
      label = userRole === 'supplier' ? "In attesa conferma bozza" : "Conferma bozza";
      color = userRole === 'supplier' ? "#ffb400" : "red";  // Colori per conferma bozza
      break;
    case 5:
      label = userRole === 'supplier' ? "Conferma spedizione" : "IS in attesa di spedizione";
      color = userRole === 'supplier' ? "red" : "#ffb400";  // Colori per conferma spedizione
      break;
    case 6:
      label = userRole === 'supplier' ? "In attesa ricezione" : "Conferma ricezione";
      color = userRole === 'supplier' ? "#ffb400" : "red";  // Colori per conferma ricezione
      break;
    case 7:
      label = "Visualizza";
      color = "green";  // Verde per visualizzare l'ordine completato
      break;
    case 8:
      label = "Archiviato";
      color = "gray";  // Grigio per ordini archiviati
      break;
  }

  return { label, color };  // Ritorna l'etichetta e il colore
};

export const mapBoxQuotationStatus = (status) => {
  switch (status) {
    case 'draft':
      return 'In attesa di invio';
    case 'sent':
      return 'In attesa del prezzo';
    case 'pending':
      return 'In attesa conferma ordine';
    case 'closed':
      return 'Ordine completato';
    case 'refused':
      return 'Ordine rifiutato';
    case 'archived':
      return 'Archiviato';
    default:
      return 'Stato sconosciuto';
  }
};