import Vue from 'vue'
import Router from 'vue-router'
import Home from './screens/Home.vue'
import Login from './screens/Login.vue'
import Admin from './screens/Admin.vue'
import Customers from './screens/Customers.vue'
import Projects from './screens/Projects.vue'
import Project from './screens/Project.vue'
import Customer from './screens/Customer.vue'
import Workflow from './components/Workflow.vue'
import Plotter from './screens/Plotter.vue'
import Production from './screens/Production.vue'
import To_production from './screens/ToProduction.vue'
import Reports from './screens/Reports.vue'
import Report from './components/Report.vue'
import Timeline from './screens/Timeline.vue'
import Settings from './screens/Settings.vue'
import Contestazione from './screens/Contestazione.vue'
import Contestazioni from './screens/Contestazioni.vue'
import Pagamenti from './screens/Pagamenti.vue'
import ProjectsEsterno from './screens/ProjectsEsterno.vue'
import ProjectEsterno from './screens/ProjectEsterno.vue'
import Stock from './screens/Stock.vue'
import Bin from './screens/Bin.vue'
import BinEsterno from './screens/BinEsterno.vue'
import ChangePasswword from './screens/ChangePassword.vue'
import LoadingAuth from './components/LoadingAuth.vue'
import Quotation from './screens/Quotation.vue'
import BoxQuotations from './screens/BoxQuotations.vue'
import BoxQuotation from './screens/BoxQuotation.vue'

import store from "./store"

Vue.use(Router)
const router = new Router({
    mode: "hash",
    routes: [
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {
                title: 'ICA Progest', requiresAuth: true
            }

        },

        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                title: 'ICA Progest - Login',
                hideNavigation: true
            }

        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: ChangePasswword,
            meta: {
                title: 'ICA Progest - Password Reset',
                hideNavigation: true
            }

        },
        {
            path: '/auth',
            name: 'loading',
            component: LoadingAuth,
            meta: {
                title: 'ICA Progest - Caricamento',
                hideNavigation: true
            }

        },
        {
            path: '/settings',
            name: 'impostazioni',
            component: Settings,
            meta: {
                title: 'ICA Progest - Impostazioni', requiresAuth: true
            }

        },

        {
            path: '/customers',
            name: 'clienti',
            component: Customers,
            meta: {
                title: 'ICA Progest - Clienti', requiresAuth: true
            }

        },
        {
            path: '/projects-queue',
            name: 'progettiEsterni',
            component: ProjectsEsterno,
            meta: {
                title: 'ICA Progest - Progetti', requiresAuth: true
            }

        },
        {
            path: '/project-queue/:id',
            name: 'progettoEsterno',
            component: ProjectEsterno,
            meta: {
                title: 'ICA Progest - Progetto', requiresAuth: true
            },
            props: true

        },
        {
            path: '/bin',
            name: 'Cestino',
            component: Bin,
            meta: {
                title: 'ICA Progest - Cestino', requiresAuth: true
            }

        },
        {
            path: '/bin-queue',
            name: 'Cestino',
            component: BinEsterno,
            meta: {
                title: 'ICA Progest - Cestino', requiresAuth: true
            }

        },
        {
            path: '/projects',
            name: 'progettiIca',
            component: Projects,
            meta: {
                title: 'ICA Progest - Progetti', requiresAuth: true
            }

        },
        {
            path: '/disputes',
            name: 'contestazioni',
            component: Contestazioni,
            meta: {
                title: 'ICA Progest - Contestazioni', requiresAuth: true
            }

        },
        {
            path: '/quotation',
            name: 'preventivi',
            component: Quotation,
            meta: {
                title: 'ICA Progest - Preventivi', requiresAuth: true
            }

        },
        {
            path: '/production',
            name: 'Plotter',
            component: Plotter,
            meta: {
                title: 'ICA Progest - Plotter', requiresAuth: true
            }

        },

        {
            path: '/stock-productions',
            name: 'produzione',
            component: Production,
            meta: {
                title: 'ICA Progest - Produzioni', requiresAuth: true
            }

        },
        {
            path: '/stock',
            name: 'Magazzino',
            component: Stock,
            meta: {
                title: 'ICA Progest - Magazzino', requiresAuth: true
            }

        },
        {
            path: '/reports',
            name: 'reports',
            component: Reports,
            meta: {
                title: 'ICA Progest - Report', requiresAuth: true
            }

        },
        {
            path: '/payments',
            name: 'payments',
            component: Pagamenti,
            meta: {
                title: 'ICA Progest - Pagamenti', requiresAuth: true
            }

        },
        {
            path: '/box-quotation/:id',
            name: 'box-quotation',
            component: BoxQuotation,
            meta: {
                title: 'ICA Progest - Preventivo Scatola', requiresAuth: true
            }
        },
        {
            path: '/box-quotations',
            name: 'box-quotations',
            component: BoxQuotations,
            meta: {
                title: 'ICA Progest - Preventivi Scatola', requiresAuth: true
            }

        },
        {
            path: '/timeline',
            name: 'timeline',
            component: Timeline,
            meta: {
                title: 'ICA Progest - Timeline', requiresAuth: true
            }

        },
        {
            path: '/workflow',
            name: 'Spazio di Lavoro',
            component: Workflow,
            meta: {
                title: 'Spazio di lavoro', requiresAuth: true
            }

        },
        {
            path: '/project/:id',
            name: 'progettoIca',
            component: Project,
            meta: {
                meta: { transitionName: 'slide' },
                title: 'ICA Progest - Progetto', requiresAuth: true
            }

        },
        {
            path: '/dispute/:id',
            name: 'Contestazione',
            component: Contestazione,
            meta: {
                title: 'ICA Progest - Contestazione', requiresAuth: true
            }

        },
        {
            path: '/report/:id',
            name: 'report',
            component: Report,
            meta: {
                title: 'ICA Progest -Report', requiresAuth: true
            }

        },
        {
            path: '/to-production/:id',
            name: 'to_production',
            component: To_production,
            meta: {
                title: 'ICA Progest - Produzione', requiresAuth: true
            }

        },
        {
            path: '/customer/:id',
            name: 'cliente',
            component: Customer,
            meta: {
                title: 'ICA Progest - Cliente', requiresAuth: true
            }

        },
        {
            path: '/logout',
            name: 'logout',
            meta: { hideNavigation: true, requiresAuth: true }
        },
        {
            path: '/admin',
            name: 'admin',
            component: Admin,
            meta: {
                title: 'ICA Progest - Admin', requiresAuth: true
            }
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.query.requestedUrl) {
        // Clone the route object and delete the parameter
        let newRoute = {
            ...to,
            query: { ...to.query }
        };
        delete newRoute.query.requestedUrl;

        // Replace the route without the parameter
        next(newRoute);
    } else {
        next();
    }
});

export default router;