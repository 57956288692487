<template>
  <div>
    <v-btn dark class="!tw-bg-custom-blue" color="primary" @click="dialogVisible = true">
      Nuovo preventivo
    </v-btn>
    <v-dialog v-model="dialogVisible" max-width="800" persistent>
      <v-card>
        <v-card-title class="headline primary">
          Nuovo Preventivo Scatole
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <!-- Customer Selection -->
            <v-autocomplete filled v-model="form.customerId" :items="customers" item-text="name" item-value="id"
              label="Cliente*" :rules="[(v) => !!v || 'Cliente richiesto']" required :loading="customersLoading"
              class="mt-4"></v-autocomplete>
            <v-text-field filled v-model="form.name" label="Riferimento" class="mt-4"></v-text-field>

            <!-- Machine Supplier Selection -->
            <v-select filled v-model="form.machineSupplierIds" :items="machineSuppliers" item-text="username"
              item-value="id" label="Fornitori Macchina" multiple :loading="suppliersLoading" class="mt-4"></v-select>

            <!-- Dimensions -->
            <v-text-field filled v-model="form.dimensions" label="Dimensioni*" hint="Es: 100x200x300mm"
              :rules="[(v) => !!v || 'Dimensioni richieste']" required class="mt-4"></v-text-field>

            <!-- Composition -->
            <v-textarea filled v-model="form.composition" label="Composizione*"
              :rules="[(v) => !!v || 'Composizione richiesta']" required class="mt-4"></v-textarea>

            <!-- Quantity -->
            <v-text-field filled v-model.number="form.quantity" type="number" label="Quantità" min="1"
              :rules="[(v) => v > 0 || 'Quantità deve essere maggiore di 0']" class="mt-4"></v-text-field>

            <!-- Press -->
            <v-text-field filled v-model="form.press" label="Stampa" class="mt-4"></v-text-field>

            <!-- Notes -->
            <v-textarea filled v-model="form.notes" label="Note" class="mt-4"></v-textarea>

            <!-- Attachments -->
            <v-file-input filled v-model="attachmentsFiles" label="Allegati" multiple chips show-size
              truncate-length="50" @change="handleFileUpload" class="mt-4"></v-file-input>

            <v-card v-if="form.attachments.length > 0" class="mt-4">
              <v-card-title>Allegati Caricati</v-card-title>
              <v-card-text>
                <div v-for="(file, index) in form.attachments" :key="index" class="tw-flex tw-items-center tw-mb-2">
                  <v-icon class="tw-mr-2">mdi-file</v-icon>
                  <span class="tw-flex-grow">{{ file.name }}</span>
                  <v-btn icon @click="removeFile(index)">
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>

            <v-alert v-if="error" type="error" class="mt-4">
              {{ error }}
            </v-alert>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog" :disabled="loading"> Annulla </v-btn>
          <v-btn dark class="!tw-bg-custom-blue" color="primary" @click="submitForm" :loading="loading"
            :disabled="!valid">
            Crea Preventivo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dialogVisible: false,
      valid: false,
      loading: false,
      error: null,
      customersLoading: false,
      suppliersLoading: false,
      form: {
        machineSupplierIds: [],
        name: null,
        customerId: null,
        dimensions: "",
        composition: "",
        machineSupplierId: null,
        notes: "",
        quantity: 1,
        press: "",
        attachments: [],
      },
      customers: [],
      machineSuppliers: [],
      attachmentsFiles: [],
    };
  },

  watch: {
    dialogVisible(visible) {
      if (visible) {
        this.fetchCustomers();
        this.fetchMachineSuppliers();
      }
    },
  },

  methods: {
    async fetchCustomers() {
      this.customersLoading = true;
      try {
        const res = await this.axios.get("/customers")
        this.customers = res.data.customers;
      } catch (error) {
        this.error = "Errore durante il caricamento dei clienti";
      } finally {
        this.customersLoading = false;
      }
    },

    async fetchMachineSuppliers() {
      this.suppliersLoading = true;
      try {
        const res = await this.axios.get("/users", {
          params: { role: "machine_supplier" },
        });
        this.machineSuppliers = res.data.items;
      } catch (error) {
        this.error = "Errore durante il caricamento dei fornitori";
      } finally {
        this.suppliersLoading = false;
      }
    },

    handleFileUpload(files) {
      this.form.attachments = [];
      this.attachmentsFiles = [];
      
      if (!files) return;
      
      // Conserva i File objects per l'invio
      this.attachmentsFiles = [...files];
      
      // Crea preview
      files.forEach((file) => {
        this.form.attachments.push({
          name: file.name,
          size: file.size,
          type: file.type,
        });
      });
    },

    removeFile(index) {
      this.form.attachments.splice(index, 1);
      this.attachmentsFiles.splice(index, 1);
    },

    async submitForm() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      try {
        const formData = new FormData();
        
        // Crea oggetto JSON per i dati
        const jsonData = {
          machine_supplier_ids: this.form.machineSupplierIds,
          name: this.form.name,
          customer_id: this.form.customerId,
          dimensions: this.form.dimensions,
          composition: this.form.composition,
          notes: this.form.notes,
          quantity: this.form.quantity,
          press: this.form.press,
        };

        // Aggiungi JSON come campo separato
        formData.append('json_data', JSON.stringify(jsonData));
        
        // Aggiungi files
        this.attachmentsFiles.forEach((file) => {
          formData.append('attachments', file);
        });

        // Invia con content-type corretto
        const res = await axios.post("/box-quotation", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.$emit("onCreate", res.data.quotation_id);
        this.closeDialog();
        this.$store.dispatch("showSnackbar", {
          text: `${res.data.msg.text} con ${this.attachmentsFiles.length} allegati`,
          type: "success",
        });
      } catch (error) {
        this.error =
          error.response?.data?.msg?.text ||
          "Errore durante la creazione del preventivo";
      } finally {
        this.loading = false;
      }
    },

    closeDialog() {
      this.dialogVisible = false;
      this.$refs.form.reset();
      this.form = {
        name: null,
        customerId: null,
        dimensions: "",
        composition: "",
        machineSupplierId: null,
        notes: "",
        quantity: 1,
        press: "",
        attachments: [],
      };
      this.attachmentsFiles = [];
      this.error = null;
    },
  },
};
</script>