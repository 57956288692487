<template>
  <v-app v-if="appReady">
    <UpdateModal v-if="updateModal" />

    <v-app-bar app color="transparent" dark class="d-flex justify-end navv elevation-0" height="90" style="left: 0px"
      v-if="user.isAuth">
      <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition"
        content-class="not_menu !tw-shadow-xl !tw-rounded-lg !tw-min-w-[90%] md:!tw-min-w-[30%] !tw-z-50"
        v-model="not_menu">
        <template v-slot:activator="{ on, attrs }">
          <div class="tw-box-border md:tw-p-4 tw-p-0 tw-w-full tw-flex justify-end align-center toolbar-block">
            <div class="tw-flex-1 tw-flex tw-justify-start">
              <v-icon @click.stop="drawer = !drawer"
                class="!tw-text-custom-blue md:!tw-hidden tw-block tw-text-xl">mdi-menu</v-icon>
            </div>

            <DraftCounter text="Bozze stampa da confermare:" v-if="user.role == 'master' && user.drafts_count > 0"
              :counter="user.drafts_count" />
            <DraftCounter text="Bozze fustella da confermare:"
              v-if="user.role == 'master' && user.cuttingedge_count > 0" :counter="user.cuttingedge_count" />
            <DraftCounter text="File tecnici da caricare"
              v-if="user.role == 'master' && user.dxf_count > 0" :counter="user.dxf_count" />
            <v-icon v-bind="attrs" v-on="on" size="25" class="!tw-text-dark hover:tw-opacity-80">mdi-bell</v-icon>
            <v-badge color="#11797B" class="!tw-text-" v-if="unread_notifications.length > 0"
              :content="unread_notifications.length"></v-badge>
          </div>
        </template>
        <div class="tw-box-border tw-px-4 tw-pt-4">
          <div class="tw-flex tw-justify-between">
            <div class="tw-flex tw-items-center">
              <div class="tw-text-2xl tw-font-bold">Notifiche</div>
              <secondary-btn class="tw-ml-2" @click.native="seeAll" text="Segna come lette"></secondary-btn>
            </div>
            <v-icon @click="not_menu = false" class="tw-text-gray-400">mdi-close</v-icon>
          </div>
          <v-tabs v-model="notifications_tab" background-color="transparent">
            <v-tabs-slider color="#11797b"></v-tabs-slider>
            <v-tab href="#mobile-tabs-5-1"> Da leggere </v-tab>
            <v-tab href="#mobile-tabs-5-2"> Lette </v-tab>
          </v-tabs>

          <v-tabs-items v-model="notifications_tab">
            <v-tab-item :value="'mobile-tabs-5-1'">
              <div class="tw-mt-2">
                <div class="tw-w-full tw-h-full">
                  <v-virtual-scroll v-if="unread_notifications.length > 0" :items="unread_notifications" height="300"
                    item-height="200" class="tw-box-border">
                    <template v-slot:default="{ item, index }">
                      <v-list-item :key="index + 'i'"
                        class="tw-w-full tw-box-border tw-p-4 tw-flex hover:tw-bg-grey tw-rounded-xl tw-cursor-pointer"
                        @click="
                          seeNotification(item, exportNotification(item, index))
                          ">
                        <div class="tw-w-4 tw-h-4 tw-rounded-full tw-bg-custom-blue tw-mb-auto tw-mr-2 tw-mt-1"></div>
                        <div class="tw-flex-1">
                          <div class="tw-flex- tw-flex-col">
                            <div class="tw-text-lg tw-font-semibold tw-mb-2">
                              {{ item.text }}
                            </div>
                            <div class="tw-text-sm font-bold font2">
                              Cliente: {{ item.customer }}
                            </div>
                            <div class="tw-text-sm font-bold font2">
                              Riferimento: {{ item.rif }}
                            </div>
                            <div class="tw-flex tw-justify-between tw-items-center font2 tw-text-xs tw-mt-4">
                              <v-chip color="#DDEBEB">{{ item.user }}</v-chip>
                              <div>{{ item.date }}</div>
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <p v-else style="text-align: center; margin-top: 16px">
                    Nessuna notifica da leggere
                  </p>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item :value="'mobile-tabs-5-2'">
              <div class="tw-mt-2">
                <div class="tw-w-full tw-h-full">
                  <v-virtual-scroll v-if="read_notifications.length > 0" :items="read_notifications" height="300"
                    item-height="200" class="tw-box-border">
                    <template v-slot:default="{ item, index }">
                      <v-list-item :key="index + 'i'"
                        class="tw-w-full tw-box-border tw-p-4 tw-flex hover:tw-bg-grey tw-rounded-xl tw-cursor-pointer"
                        @click="
                          seeNotification(item, exportNotification(item, index))
                          ">
                        <div class="tw-w-4 tw-h-4 tw-rounded-full tw-bg-custom-blue tw-mb-auto tw-mr-2 tw-mt-1"></div>
                        <div class="tw-flex-1">
                          <div class="tw-flex- tw-flex-col">
                            <div class="tw-text-lg tw-font-semibold tw-mb-2">
                              {{ item.text }}
                            </div>
                            <div class="tw-text-sm font-bold font2">
                              Cliente: {{ item.customer }}
                            </div>
                            <div class="tw-text-sm font-bold font2">
                              Riferimento: {{ item.rif }}
                            </div>
                            <div class="tw-flex tw-justify-between tw-items-center font2 tw-text-xs tw-mt-4">
                              <v-chip color="#DDEBEB">{{ item.user }}</v-chip>
                              <div>{{ item.date }}</div>
                            </div>
                          </div>
                        </div>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <p v-else style="text-align: center; margin-top: 16px">
                    Nessuna notifica
                  </p>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-menu>
      <!-- <div v-if="user.username == 'Jacopo'" class="tw-ml-8">
      <div class="tw-text-sm tw-text-dark"><b>In corso: </b>{{user.priority_limit.in_corso.limit}}</div>
      <div class="tw-text-sm tw-text-dark"><b>Urgente: </b>{{user.priority_limit.urgente.limit}}</div>
    </div> -->

      <div class="tw-flex tw-items-center tw-ml-8">
        <p class="tw-text-dark tw-font-bold text-md mb-0 font2 mr-2 md:tw-block tw-hidden">
          Ciao, {{ user.username }}
        </p>
        <v-menu transition="slide-y-transition" content-class="!tw-shadow-xl !tw-rounded-lg" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon size="25" v-bind="attrs" v-on="on"
              class="!tw-text-dark tw-bg-azure tw-rounded-full tw-p-2 tw-box-border tw-cursor-pointer hover:tw-opacity-80">mdi-account</v-icon>
          </template>
          <v-list class="tw-p-4">
            <v-list-item class="!tw-mb-6 hover:!tw-bg-azure !tw-text-dark list-item tw-rounded-lg"
              @click="$router.push('/settings').catch(() => { })" link>
              <v-icon class="!tw-text-inherit tw-mr-2 !tw-text-dark">mdi-cog</v-icon>
              <span class="!tw-text-inherit">Impostazioni</span>
            </v-list-item>
            <v-list-item class="!tw-mb-6 hover:!tw-bg-azure list-item !tw-text-dark tw-rounded-lg"
              @click="info_dialog = true" link>
              <v-icon class="!tw-text-inherit tw-mr-2 !tw-text-dark">mdi-information</v-icon>
              <span class="!tw-text-inherit">Informazioni</span>
            </v-list-item>
            <v-list-item class="!tw-mb-6 hover:!tw-bg-azure !tw-text-dark list-item tw-rounded-lg"
              @click="openPatchModal" link>
              <v-icon class="!tw-text-inherit tw-mr-2 !tw-text-dark">mdi-history</v-icon>
              <span class="!tw-text-inherit">Note Patch</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-dialog v-model="info_dialog" width="500">
        <v-card style="padding: 20px">
          <v-card-title>
            Sviluppato da Jacopo Mossa
            <br />
            Per assistenza chiamare il <b>+39 347 383 2957</b>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="info_dialog = false">
              Chiudi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <div class="md:!tw-block !tw-hidden">
      <v-navigation-drawer v-if="!$route.meta.hideNavigation" app floating color="#2f2f2f" class="nav" dark permanent
        mini-variant-width="60px">
        <v-list-item style="height: 90px; -webkit-app-region: drag">
        </v-list-item>
        <v-list dense nav>
          <v-list-item class="!tw-mb-6 hover:!tw-bg-azure hover:!tw-text-dark list-item !tw-text-white"
            v-for="item in roles" :key="item.title" @click="$router.push(item.path).catch(() => { })" link>
            <v-icon class="!tw-text-inherit">{{ item.icon }}</v-icon>
            <span class="!tw-text-inherit tw-ml-2">{{ item.title }}</span>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <div>
            <v-list dense nav>
              <v-list-item class="!tw-mb-6 hover:!tw-bg-azure hover:!tw-text-dark list-item !tw-text-white"
                @click="Logout" link>
                <v-icon class="!tw-text-inherit">mdi-logout</v-icon>
                <span class="!tw-text-inherit">Logout</span>
              </v-list-item>
            </v-list>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
    <div class="md:!tw-hidden !tw-block">
      <v-navigation-drawer v-if="!$route.meta.hideNavigation" absolute color="#2f2f2f" dark temporary v-model="drawer"
        app content-class="tw-z-[2000]" overlay>
        <v-list-item style="height: 90px; -webkit-app-region: drag">
          <div>
            <v-icon @click="drawer = false" class="tw-text-white">mdi-close</v-icon>
          </div>
        </v-list-item>
        <v-list dense nav>
          <v-list-item
            class="md:!tw-mb-6 !tw-mb-3 md:tw-text-md tw-text-xs hover:!tw-bg-azure hover:!tw-text-dark list-item !tw-text-white"
            v-for="item in roles" :key="item.title" @click="$router.push(item.path).catch(() => { })" link>
            <v-icon class="!tw-text-inherit">{{ item.icon }}</v-icon>
            <span class="!tw-text-inherit tw-ml-2">{{ item.title }}</span>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <div>
            <v-list dense nav>
              <v-list-item
                class="!tw-mb-32 hover:!tw-bg-azure hover:!tw-text-dark list-item !tw-text-white md:tw-text-md tw-text-xs"
                @click="Logout" link>
                <v-icon class="!tw-text-inherit">mdi-logout</v-icon>
                <span class="!tw-text-inherit">Logout</span>
              </v-list-item>
            </v-list>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
    <v-card height="100vh" class="mx-auto no-sh tw-min-w-[32px] tw-flex-1">
      <v-main class="max tw-bg-grey">
        <v-container id="max" fluid>
          <v-row class="fill-height">
            <v-col class="no-pd">
              <transition :name="transitionName" mode="out-in" @beforeLeave="beforeLeave" @enter="enter">
                <router-view :key="$route.fullPath" v-slot="{ Home }">
                  <component :is="Home" :user="user.username" />
                </router-view>
              </transition>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-card>

    <v-alert dense type="error" :value="conn_issue" class="issue">
      Sembra ci sia un <b>problema</b> di <b>connessione al server.</b><br />
      Contatta un amministratore o chiama il numero 347 383 2957.
    </v-alert>

    <PatchInfoModal></PatchInfoModal>
  </v-app>
  <div v-else
    class="tw-w-full tw-z-50 tw-h-screen tw-fixed tw-bottom-0 tw-left-0 tw-flex tw-justify-center tw-items-center tw-bg-grey">
    <v-progress-circular indeterminate color="#2F2F2F"></v-progress-circular>
  </div>
</template>

<script>
const DEFAULT_TRANSITION = "fade";

import PatchInfoModal from "./components/PatchInfoModal.vue";
import UpdateModal from "./components/UpdateModal.vue";
import DraftCounter from "./components/NavBarComponents/DraftCounter.vue";

import { version } from "../package";
import axios from "./plugins/axios";
var audio = new Audio(require("./assets/audio/notification/notification.mp3"));
import EventBus from "./plugins/eventBus";

export default {
  name: "App",
  components: {
    PatchInfoModal,
    UpdateModal,
    DraftCounter,
  },

  props: ["notify", "location"],

  data: () => ({
    drawer: false,
    redirect_path: "login",
    appReady: false,
    prevHeight: 0,
    transitionName: DEFAULT_TRANSITION,
    notifications_tab: "mobile-tabs-5-1",
    tab_items: [
      { tab: "Da leggere", content: "unread" },
      { tab: "Lette", content: "read" },
    ],
    not_menu: false,
    appVersion: version,
    info_dialog: false,
    updating: false,
    conn_issue: false,
    notifications: [],
    splitted_not: [],
    notification_counter: 0,
    role: "",
    inizialize: false,
    path: "",
    is_updated: false,
    mini: true,
    is_loading: 0,
    settings: { desktop_notifications: false, notification: true },
    counter: 0,
  }),

  computed: {
    items() {
      return [
        {
          title: "Clienti",
          icon: "mdi-account-group",
          path: "/customers",
          roles: ["commerciale", "master"],
        },
        {
          title: "Progetti",
          icon: "mdi-table-edit",
          path: "/projects",
          roles: ["commerciale", "master"],
        },
        // {
        //   title: 'Preventivi',
        //   icon: "mdi-package-variant-closed",
        //   path: "/box-quotations",
        //   roles: ["commerciale", "master"],
        // },
        {
          title: this.user.role == "esterno" ? "Progetti" : "Collaboratori",
          icon: "mdi-propane-tank",
          path: "/projects-queue",
          roles: ["commerciale", "esterno"],
        },
        {
          title: "Campionatura",
          icon: "mdi-robot-industrial",
          path: "/production",
          roles: ["commerciale", "master", "worker"],
        },
        {
          title: "Impianti",
          icon: "mdi-cash",
          path: "/quotation",
          roles: ["commerciale", "supplier", "master"],
        },
        {
          title: "Produzioni",
          icon: "mdi-package-variant-closed",
          path: "/stock-productions",
          roles: ["commerciale", "magazzino", "master", "worker"],
        },
        {
          title: "Stock",
          icon: "mdi-package-variant-closed",
          path: "/stock",
          roles: ["commerciale", "logistic", "magazzino"],
        },
        {
          title: "Dati",
          icon: "mdi-chart-areaspline",
          path: "/home",
          roles: ["commerciale", "master", "contabile"],
        },
        // {
        //   title: "Contestazioni",
        //   icon: "mdi-alert-rhombus",
        //   path: "/disputes",
        //   roles: ["commerciale"],
        // // },
        // {
        //   title: "Pagamenti",
        //   icon: "mdi-currency-eur",
        //   path: "/payments",
        //   roles: ["commerciale", "contabile"],
        // },
        //{ title: 'Timeline', icon: 'mdi-currency-eur', path:'/timeline' },
        { title: "Admin", icon: "mdi-view-dashboard", path: "/admin" },
        {
          title: "Cestino",
          icon: "mdi-trash-can",
          path: "/bin",
          roles: ["master", "commerciale"],
        },
        {
          title: "Cestino ",
          icon: "mdi-trash-can",
          path: "/bin-queue",
          roles: ["esterno"],
        },
      ];
    },

    user: {
      get() {
        return this.$store.getters.getCurrentUser;
      },
      set() {
        return this.$store.getters.getCurrentUser;
      },
    },

    updateModal() {
      return this.$store.state.updateModal;
    },
    unread_notifications() {
      if (this.user.notifications) {
        return this.user.notifications.filter((item) => !item.has_seen);
      } else {
        return [];
      }
    },
    read_notifications() {
      if (this.user.notifications) {
        return this.user.notifications.filter((item) => item.has_seen);
      } else {
        return [];
      }
    },

    roles() {
      var items = [];
      this.items.forEach((element) => {
        if (element.roles && element.roles.indexOf(this.user.role) != -1) {
          items.push(element);
        } else if (
          (!element.roles && this.user.username == "Jacopo") ||
          this.user.username == "Fabiano"
        ) {
          items.push(element);
        }
      });

      return items;
    },
  },
  beforeCreate: async function () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const requestedUrl = urlParams.get("requestedUrl");
    const id = urlParams.get("id");
    await axios
      .get("auth" + (requestedUrl ? "?requestedUrl=" + requestedUrl : ""))
      .then(async (res) => {
        const settler = new Promise((resolve, reject) => {
          if (res.data.user) {
            axios
              .get("auth-profile/" + "?getV=" + version)
              .then((response) => {
                response.data.user.isAuth = true;
                let res_data = response.data.user;
                res_data.focus_mode = response.data.focus_mode;
                this.$store.dispatch("setuser", res_data);
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve();
          }
        });
        settler.then((response) => {

          this.appReady = true;
          this.$router
            .replace({
              name: res.data.route,
              params: { id: res.data.action, choosenEsterno: res.data.esterno },
            })
            .catch((err) => { });
          if (urlParams.has("requestedUrl")) {
            urlParams.delete("requestedUrl");
            const newUrl =
              window.location.pathname +
              window.location.hash.replace(/&?requestedUrl=[^&]*/i, "");
            window.history.replaceState({}, "", newUrl);
          }
        });
      })
      .catch((err) => {
        this.appReady = true;
        this.$router.push("/login").catch(() => { });
      })
      .finally(() => {
      });
  },

  created: function () {
    this._inizializeRouteTransition();
    this.$root.$on("update-user", () => {
      console.log("updating");
      this.getUser();
    });
  },

  mounted() {
    EventBus.$on("data-received", (data) => {
      if (data.type == "notification-added") {
        this.getUser();
      }
    });
    this.detectTabClose();
    // on eventBus notification-added
  },
  watch: {
    unread_notifications(newV, oldV) {
      if (this.user) {
        if (newV.length > oldV.length && oldV.length == 0) {
          if (this.user.settings.desktop_notifications) {
            if (newV.length == 1) {
              this.showToast("Hai una notifica da leggere.");
            } else {
              this.showToast("Hai " + newV.length + " notifiche da leggere.");
            }
          }
        } else if (newV.length > oldV.length && oldV.length > 0)
          if (this.user.settings.desktop_notifications) {
            this.showToast(
              "Hai una nuova notifica da " + newV[newV.length - 1].user
            );
            this.audioNotification();
          }
      }
    },
    not_menu(newV, oldV) {
      if (!newV) {
        //this.seeAll()
      }
    },
  },

  methods: {
    openPatchModal() {
      this.user.patch_info = 1;
      axios.post("patch-open");
    },
    audioNotification() {
      audio.play();
    },
    beforeLeave(element) {
      this.prevHeight = window.getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = window.getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    _inizializeRouteTransition() {
      this.$router.beforeEach((to, from, next) => {
        let transitionName = to.meta.transitionName || from.meta.transitionName;

        if (transitionName === "slide") {
          const toDepth = to.path.split("/").length;
          const fromDepth = from.path.split("/").length;
          transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
        }

        this.transitionName = transitionName || DEFAULT_TRANSITION;

        next();
      });
    },
    exportNotification(notification, index) {
      var result = {};
      var redirect;

      if (notification.type == "createdProject" && notification.esterno) {
        redirect = "project-queue";
      }
      if (notification.type == "request" && !notification.esterno) {
        redirect = "project";
      } else if (notification.type == "request" && notification.esterno) {
        redirect = "project-queue";
      } else if (notification.type == "createdNote" && notification.esterno) {
        redirect = "project-queue";
      } else if (notification.type == "completed" && notification.esterno) {
        redirect = "project-queue";
      } else if (notification.type == "completed" && !notification.esterno) {
        redirect = "project";
      } else if (notification.type == "prev" && !notification.esterno) {
        redirect = "project";
      } else if (notification.type == "prev" && notification.esterno) {
        redirect = "project-queue";
      } else if (
        notification.type == "missing-informations-ask" &&
        notification.esterno
      ) {
        redirect = "project-queue";
      } else if (
        notification.type == "missing-informations-response" &&
        notification.esterno
      ) {
        redirect = "project-queue";
      } else if (
        notification.type == "missing-informations-ask" &&
        !notification.esterno
      ) {
        redirect = "project";
      } else if (
        notification.type == "missing-informations-response" &&
        !notification.esterno
      ) {
        redirect = "project";
      } else if (notification.type == "new production") {
        redirect = "project";
      } else if (notification.type == "confirmed production") {
        redirect = "to-production";
      } else if (notification.type == "completed production") {
        redirect = "to-production";
      } else if (notification.type == "new-payment") {
        redirect = "payments";
      } else if (notification.type == "payment-accountant") {
        redirect = "payments";
      } else if (notification.type == "payment-verification") {
        redirect = "project";
      } else if (notification.type == "new-dispute") {
        redirect = "project";
      } else if (notification.type.includes("supplierQuotation")) {
        redirect = "quotation";
      }

      result.notification = notification;
      result.index = index;
      result.redirect = redirect;

      return result;
    },
    showToast(text) {
      this.$notification.show(
        "ICA Progest",
        {
          body: text,
        },
        {}
      );
    },
    detectTabClose() {
      window.addEventListener("beforeunload", (e) => {
        axios
          .post("/closeall/" + this.user.username + "/")
          .then(() => {
            console.log("Post before tab closing");
          })
          .catch(() => {
            console.log("Error on post");
          });
      });
    },

    seeAll() {
      this.not_menu = false;
      axios.post("see-all").then((response) => {
        this.$store.state.currentUser.notifications =
          response.data.notifications;
      });
    },
    Logout: function () {
      this.$confirm("Sei sicuro di voler effettuare il logout?").then((res) => {
        if (res) {
          axios.post("logout").then((response) => {
            this.$store.dispatch("unsetdata");
            this.$router.push("/login").catch(() => { });
          });
        }
      });
    },

    getUser() {
      if (
        this.$route.path != "/login" &&
        this.$route.path != "/reset-password"
      ) {
        try {
          axios.get("auth-profile/" + "?getV=" + version).then((response) => {
            if (!response.data.isUpdated) {
              if (!window.location.href.includes("project/")) {
                if (!response.data.isUpdated) {
                  this.$store.state.updateModal = true;
                }
              }
            }
            let res_data = response.data.user;
            res_data.focus_mode = response.data.focus_mode;
            this.$store.dispatch("setuser", res_data);
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    seeNotification: function (item, path) {
      var patt = window.location;
      axios
        .post("delete-notification/", {
          id: item.id,
          index: path.index,
          redirect: path.redirect,
          has_seen: item.has_seen
        })

        .then((response) => {

          this.not_menu = false;
          this.user.notifications = response.data.notifications;
          const fullPath = `${path.redirect}/${response.data._id}`

          this.$router.replace('/').catch(() => { });

          this.$router.replace(fullPath).catch((error) => {
            console.error("Navigation error:", error);
          });

          const urlParams = new URLSearchParams(window.location.search);

          if (urlParams.toString()) {
            const newUrl = window.location.pathname + window.location.hash;
            window.history.replaceState({}, "", newUrl);
          }

        });
    },
  },
};
</script>
<style>
.unread-not {
  background-color: #f3f3f3;
}

.not_menu {
  left: auto !important;
  max-height: max-content !important;
}

.v-application--wrap {
  max-height: 100vh;
  flex-direction: row !important;
}

.small-text {
  font-size: 10px;
  float: right;
  text-align: right;
}

.margin-n {
  margin-left: 10px;
}

.no-pd {
  padding: 0 !important;
}

.no-pd-tb {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

#max {
  height: calc(100vh - 90px);
  padding: 0 !important;
  z-index: 350 !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.max {
  padding: 90px 0px 0px 0px !important;
}

.title {
  margin-top: 15px;
}

.nav,
.navv {
  border-radius: 0 !important;
  box-shadow: 0px 0px 8px rgba(99, 99, 99, 0.349);
}

.nav {
  width: fit-content !important;
  box-sizing: border-box;
  position: static !important;
  padding-left: 20px;
  padding-right: 20px;
  overflow: visible !important;
}

.log-icon {
  margin-left: 30px;
}

.shrink {
  cursor: pointer;
}

.mg-l {
  margin-left: 5px;
}

.mg-r {
  margin-right: 5px;
}

.mg-t {
  margin-top: 8px;
}

.not_menu {
  max-height: 400px;
  padding: 5px;
  background-color: white;
  width: 30% !important;
  right: 16px !important;
}

.no-pd {
  padding: 0;
}

.no-mg {
  margin: 0;
}

.v-toolbar__content,
.v-toolbar__extension {
  width: 100% !important;
  justify-content: flex-end !important;
  -webkit-user-select: none;
  -webkit-app-region: drag;
  justify-content: center;
  padding: 4px 40px !important;
}

.toolbar-block {
  width: fit-content;
  text-transform: capitalize;
}

.no-sh {
  box-shadow: none !important;
}

.v-data-footer__icons-before {
  display: none;
}

.issue {
  position: fixed !important;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  bottom: 0;
}

.mg-bt {
  margin-top: 2px;
  margin-bottom: 2px;
}

.list-it {
  border-radius: 8px;
  box-sizing: border-box;
  padding: 15px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.list-item:hover>i {
  color: #2f2f2f !important;
}

.list-item:hover>span {
  color: #2f2f2f !important;
}

.select-style-chooser .vs__search::placeholder,
.select-style-chooser .vs__dropdown-toggle,
.select-style-chooser .vs__dropdown-menu {
  border: none;
  white-space: nowrap;
}

.select-style-chooser .vs__clear,
.select-style-chooser .vs__open-indicator {
  fill: #394066;
}

.select-style-chooser .vs__dropdown-menu {
  z-index: 3000 !important;
  opacity: 1 !important;
  border-radius: 10px;
}

input:disabled.inpt {
  opacity: 0.6;
  cursor: not-allowed;
}

::-webkit-scrollbar {
  width: 2px;
  z-index: 4000;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-virtual-scroll__item {
  margin-right: 15px;
  margin-left: 10px;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  background-color: transparent !important;
  cursor: not-allowed;
  opacity: 0.5;
}

.v-overlay__scrim {
  border-radius: 0 !important;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.v-navigation-drawer--temporary {
  z-index: 2000 !important;
}

.v-overlay--absolute {
  z-index: 10;
}
</style>
