<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8" class="!tw-p-0 tw-items-center tw-mb-4">
        <div class="tw-flex tw-items-center">
          <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">
            Produzioni
          </h1>
          <input-field
            class="ml-8 !tw-w-96"
            type="text"
            icon="mdi-magnify"
            v-model="search"
            placeholder="Cerca tra le produzioni"
            @input="saveSearch"
          ></input-field>
          <div>
            <v-icon
              @click="setViewMode"
              class="!tw-text-custom-blue !tw-text-4xl hocer:tw-opacity-80 tw-cursor-pointer"
              >{{
                currentViewMode == "list" ? "mdi-view-column" : "mdi-view-list"
              }}</v-icon
            >
          </div>
          <div class="tw-flex tw-items-center !tw-ml-4">
            <div class="tw-w-3 tw-h-3 tw-bg-custom-red tw-rounded-full"></div>
            <div class="!tw-ml-2 tw-text-xs">
              I blocchi in rosso indicano la presenza di lavorazioni precedenti
              non ancora completate, relative alla stessa referenza
            </div>
          </div>
        </div>
      </v-col>
      <v-skeleton-loader
        :loading="loading"
        transition="fade-transition"
        class="dt"
        height="500"
        type="table"
        v-if="currentViewMode == 'list'"
      >
        <table class="tw-w-full tw-bg-white tw-rounded-2xl">
          <thead>
            <tr>
              <th
                class="!tw-p-2 !tw-font-regular"
                v-for="header in headers"
                :key="header.text"
              >
                {{ header.text }}
                <v-icon
                  v-if="header.icon"
                  @click="header.action"
                  class="!tw-text-custom-blue"
                  >{{ header.icon }}</v-icon
                >
              </th>
            </tr>
          </thead>
          <template v-for="group in groups">
            <tr
              :key="group"
              class="tw-p-2 !tw-bg-opacity-60"
              :style="{ background: mapGroupHead(group).color }"
              v-if="mapGroup(group).length > 0"
            >
              <td class="tw-p-4 tw-text-white">
                {{ mapGroupHead(group).text }}
              </td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
              <td class="tw-p-4"></td>
            </tr>
            <draggable
              :key="group + 'draggable'"
              :list="mapGroup(group)"
              tag="tbody"
              @start="dragging = true"
              @end="handleDragEnd"
              :move="checkMove"
              :disabled="
                group != 'queue' &&
                (user.role != 'commerciale' || user.role != 'master')
              "
            >
              <tr
                class="tw-border-b-[1px] !tw-text-sm hover:!tw-bg-grey hover:!tw-bg-opacity-40 tw-border-azure"
                v-for="(item, index) in mapGroup(group)"
                :key="index"
              >
                <td class="tw-p-3">
                  {{ item.CodiceArticolo }}
                </td>
                <td class="tw-p-3">{{ item.RagioneSociale }}</td>
                <td class="tw-p-3">{{ item.Descrizione }}</td>
                <td class="tw-p-3">{{ item.Riferimento }}</td>
                <td class="tw-p-3">
                  {{ item.DataConsRic ? formatDate(item.DataConsRic) : moment(item.delivery_date).format('L')}}
                </td>
                <td class="tw-p-3">
                  <div
                    v-for="(man, index2) in getManTypes(item.manufactures)"
                    :key="index2"
                  >
                    {{ man
                    }}{{
                      getManTypes(item.manufactures).length == index2 + 1
                        ? ""
                        : ","
                    }}
                  </div>
                </td>
                <td class="tw-p-3">
                  <div class="tw-flex tw-items-center">
                    <v-chip
                      :color="getCurrentStatus(item).color"
                      dark
                      class="capitalizer"
                    >
                      {{ getCurrentStatus(item).text }}
                    </v-chip>
                    <div
                      class="tw-ml-2 !tw-w-4 !tw-h-4 tw-bg-custom-red tw-rounded-full tw-animate-pulse"
                      v-if="item.state === 1"
                    ></div>
                  </div>
                </td>
                <td class="tw-p-3">{{ item.Resa }}</td>
                <td class="tw-p-3">{{ item.NumeroPrevisto ? item.NumeroPrevisto : item.requested_qty }}</td>
                <td class="tw-p-3">
                  {{
                    item.manufactures
                      ? calcProduced(item)
                      : item.NumeroEffettivo
                  }}
                </td>
                <td class="tw-p-3">
                  <div class="tw-flex tw-justify-start tw-items-center">
                    <ToggleQueue
                      v-if="
                        (user.role == 'commerciale' || user.role == 'master') &&
                        item.manufactures
                      "
                      :_id="item.id_prod"
                      :queue="item.queue"
                      @updateQueue="updateQueue"
                    />
                    <v-icon
                      v-if="item._id"
                      class="tw-mx-1 tw-ml-4 !tw-text-dark hover:tw-opacity-80"
                      @click="openManufactureModal(item)"
                      >mdi-eye</v-icon
                    >
                    <v-icon
                      class="tw-mx-1 !tw-text-custom-blue hover:tw-opacity-80"
                      v-if="user.role != 'magazzino' && user.role != 'worker'"
                      @click="openUploadBoardModal(item)"
                      >mdi-upload</v-icon
                    >
                  </div>
                </td>
              </tr>
            </draggable>
          </template>
        </table>
      </v-skeleton-loader>
      <v-skeleton-loader
        :loading="loading"
        transition="fade-transition"
        class="dt"
        height="500"
        type="table"
        v-else
      >
        <v-data-table
          item-key="_id"
          :item-class="getItem"
          hide-default-footer
          :headers="headers"
          loading="true"
          loading-text="Sto caricando..."
          :items-per-page="itemsPerPage"
          class="elevation-1 dt font2 !tw-rounded-lg !tw-bg-[#f5f5f5] hover:!tw-bg-[#f5f5f5]"
          height="100%"
          :search="search"
          @page-count="pageCount = $event"
          :page.sync="page"
          group-by="queue"
          group-desc
          :single-expand="singleExpand"
          :expanded.sync="expanded"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td v-if="item.manufactures" :colspan="headers.length">
              <div
                v-for="(key, index) in Object.keys(item.manufactures)"
                :key="index"
                class="tw-flex-col"
              >
                <div
                  v-for="(manufacture, index2) in item.manufactures[key]"
                  :key="index2"
                  class="tw-flex tw-w-full"
                >
                  <div>{{ key }}</div>
                </div>
              </div>
            </td>
          </template>

          <template class="ingnore-drag" v-slot:group.header="{ group }">
            <td
              :class="
                'tw-text-white ignore-drag ' +
                (group
                  ? '!tw-bg-custom-red !tw-bg-opacity-60'
                  : '!tw-bg-[#FFA500] !tw-bg-opacity-80')
              "
              :colspan="headers.length"
            >
              <span>{{ group ? "Coda Produzione" : "In attesa" }}</span>
            </td>
          </template>
          <template v-slot:item.Stato="{ item }">
            <div class="tw-flex tw-items-center">
              <v-chip
                :color="getCurrentStatus(item).color"
                dark
                class="capitalizer"
              >
                {{ getCurrentStatus(item).text }}
              </v-chip>
              <div
                class="tw-ml-2 !tw-w-4 !tw-h-4 tw-bg-custom-red tw-rounded-full tw-animate-pulse"
                v-if="item.state === 1"
              ></div>
            </div>
          </template>

          <template v-slot:body="{}">
            <tbody>
              <tr class="">
                <td
                  class="!tw-min-h-full !tw-h-20 tw-align-top"
                  v-for="(header, index) in headers"
                  :key="index"
                >
                  <div
                    :class="
                      'tw-flex tw-flex-col  tw-h-full !tw-border-azure  ' +
                      (index != headers.length - 1 ? 'tw-border-r-2' : '')
                    "
                  >
                    <draggable
                      tag="ul"
                      @start="dragging = true"
                      :key="header + 'draggable'"
                      :list="manufactureItems[header.text]"
                      @end="onEndDrag($event, header.text)"
                      :disabled="
                        user.role != 'commerciale' && user.role != 'master'
                      "
                    >
                      <transition-group
                        type="transition"
                        :name="!dragging ? 'flip-list' : null"
                      >
                        <li
                          v-for="(obj, index2) in manufactureItems[header.text]"
                          :key="index2"
                          class="tw-my-3 tw-mx-3"
                        >
                          <div
                            :class="`tw-flex tw-w-full tw-items-center 
                          ${
                            obj.manufactures[header.text][
                              obj.manufactures[header.text].length - 1
                            ].state != 3 &&
                            Object.keys(obj.manufactures).length > 1 &&
                            (getManTypes(obj.manufactures).pop() === header.text)
                              ? 'tw-bg-custom-red tw-bg-opacity-40'
                              : 'tw-bg-white'
                          } tw-shadow-md tw-p-3 tw-rounded-xl tw-justify-between tw-cursor-pointer`"
                          >
                            <div class="">
                              <b>{{ obj.RagioneSociale }}</b>

                              <div>{{ obj.Descrizione }}</div>
                            </div>

                            <div class="tw-flex tw-items-center">
                              <v-icon
                                class="tw-mx-1 tw-ml-4 !tw-text-dark hover:tw-opacity-80"
                                v-if="obj.queue"
                                @click="openManufactureModal(obj)"
                                >mdi-eye</v-icon
                              >
                              <v-icon
                                class="tw-mx-1 !tw-text-custom-blue hover:tw-opacity-80"
                                v-if="
                                  user.role != 'magazzino' &&
                                  user.role != 'worker'
                                "
                                @click="openUploadBoardModal(obj)"
                                >mdi-upload</v-icon
                              >
                            </div>
                            <div
                              class="tw-ml-2 !tw-w-4 !tw-h-4 tw-bg-custom-red tw-rounded-full tw-animate-pulse"
                              v-if="
                                obj &&
                                obj.manufactures &&
                                Object.keys(obj.manufactures).length > 0 &&
                                Object.keys(obj.manufactures).length > 0 &&
                                obj.manufactures[
                                  header.text == 'GENERICA'
                                    ? 'generic'
                                    : header.text
                                ].length > 0 &&
                                obj.manufactures[
                                  header.text == 'GENERICA'
                                    ? 'generic'
                                    : header.text
                                ][
                                  obj.manufactures[
                                    header.text == 'GENERICA'
                                      ? 'generic'
                                      : header.text
                                  ].length - 1
                                ].state == 1
                              "
                            ></div>
                          </div>
                        </li>
                      </transition-group>
                    </draggable>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-skeleton-loader>
      <div class="text-center pt-2">
        <v-pagination
          :total-visible="7"
          color="#11797b"
          class="pag"
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </div>
    </v-row>

    <ManufactureModal
      :metadata="{}"
      @isVisibleChanged="isManufactureModalVisible = false"
      :is_visible="isManufactureModalVisible"
      :manufactureData="manufactureData"
    />
    <UploadBoardModal
      @isVisibleChanged="isUploadBoardModal = false"
      :is_visible="isUploadBoardModal"
      :ordData="uploadBoardData"
    />
    <response-notification></response-notification>
  </v-container>
</template>


<script>
import axios from "../plugins/axios";
import ManufactureModal from "../components/ProductionComponents/ManufactureModal.vue";
import UploadBoardModal from "../components/ProductionComponents/UploadBoardModal.vue";
import draggable from "vuedraggable";
import ToggleQueue from "../components/ProductionComponents/manufacture/ToggleQueue.vue";

export default {
  name: "Production",
  components: {
    ManufactureModal,
    UploadBoardModal,
    draggable,
    ToggleQueue,
    //draggable,
    //DataTableRowHandler,
  },
  data() {
    return {
      manufactureItems: [],
      manufactureOrder: [],
      dateSort: "DESC",
      totalCount: 0,
      sortLoading: false,
      manTypes: [],
      currentViewMode: localStorage.getItem("productionViewMode")
        ? localStorage.getItem("productionViewMode")
        : "list",
      expanded: [],
      singleExpand: false,
      order: null,
      sortable: null,
      isReady: false,
      dragging: false,
      manufactureData: null,
      uploadBoardData: null,
      uploadResponseData: null,
      isManufactureModalVisible: false,
      isUploadBoardModal: false,
      isResponseBoardModal: false,
      search: "",
      loading: true,
      timeout: 7000,
      page: 1,
      itemsPerPage: 100,
      polling: null,
      projects: [],
      modifiedQueueProds: [],
      groups: ["queue", "waiting", "registered", "completed"],
    };
  },
  beforeCreate: function () {
    const viewMode = localStorage.getItem("productionViewMode");
    if (!viewMode) this.currentViewMode = "list";
  },
  mounted() {
    this.getProjects().then(() => {});
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  watch: {
    dateSort(newV, oldV) {
      if (newV != oldV) {
        this.loading = true;
        this.getProjects();
      }
    },
    page(newV, oldV) {
      if (newV != oldV) {
        this.loading = true;
        this.getProjects();
      }
    },
    search(newV, oldV) {
      if (newV != oldV) {
        this.loading = true;
        this.getProjects();
      }
    },
    currentViewMode(newV, oldV) {
      localStorage.removeItem("productionViewMode");
      localStorage.setItem("productionViewMode", this.currentViewMode);
    },
    projects() {
      if (this.timeoutId) {
        clearInterval(this.timeoutId);
      }
      this.timeoutId = setInterval(
        () =>
          this.getProjects().then(() => {
            if (!this.timeoutId) {
              this.timeoutId = setInterval(
                () => this.getProjects(),
                this.timeout
              );
            }
          }),
        this.timeout
      );
    },
  },
  computed: {
    filteredManufactures() {
      const items = {};
      // Itera tutte le produzioni filtrate
      this.filteredProductions.forEach((production) => {
        Object.keys(production.manufactures).forEach((type) => {
          if (!items[type]) items[type] = [];
          items[type].push({
            ...production,
            specificManufacture: production.manufactures[type],
          });
        });
      });

      // Analizza l'ordine delle produzioni per ogni tipo
      const parsedOrder = {};
      Object.keys(this.manufactureOrder).forEach((type) => {
        if (this.manufactureOrder[type]) {
          try {
            parsedOrder[type] = JSON.parse(this.manufactureOrder[type]);
          } catch (error) {
            console.error("JSON parsing error for type " + type, error);
            parsedOrder[type] = [];
          }
        } else {
          parsedOrder[type] = [];
        }
      });

      // Ordina gli elementi per ogni tipo in base all'ordine definito in manufactureOrder
      Object.keys(items).forEach((type) => {
        if (!parsedOrder[type]) {
          parsedOrder[type] = []; // Assicura che l'array esista sempre
        }
        items[type].sort((a, b) => {
          let indexA = parsedOrder[type].indexOf(a.id_prod);
          let indexB = parsedOrder[type].indexOf(b.id_prod);
          indexA = indexA === -1 ? Infinity : indexA;
          indexB = indexB === -1 ? Infinity : indexB;
          return indexA - indexB;
        });
      });

      return items;
    },
    pageCount() {
      return Math.ceil(this.totalCount / this.itemsPerPage);
    },
    draggableItems: {
      get() {
        return this.items;
      },
      set(value) {
        this.$emit("update:items", value);
      },
    },
    headers() {
      let headers = [];
      if (this.currentViewMode == "list") {
        headers = [
          {
            text: "Codice Articolo",
            value: "CodiceArticolo",
            sortable: false,
            filterable: false,
          },
          {
            text: "Nome Cliente",
            value: "RagioneSociale",
            sortable: false,
            filterable: true,
          },
          {
            text: "Riferimento",
            value: "Descrizione",
            sortable: false,
            filterable: true,
          },
          {
            text: "Commessa",
            value: "Riferimento",
            sortable: false,
            filterable: true,
          },
          {
            text: "Consegna richiesta",
            value: "DataConsRic",
            align: "start",
            sortable: false,
            icon: "mdi-swap-vertical",
            action: () =>
              this.dateSort === "ASC"
                ? (this.dateSort = "DESC")
                : (this.dateSort = "ASC"),
          },
          {
            text: "Risorse",
            value: "Risorse",
            align: "start",
            sortable: false,
          },
          { text: "Stato", value: "Stato", sortable: false },
          {
            text: "Resa",
            value: "Resa",
            sortable: false,
          },
          {
            text: "Quantità Richiesta",
            value: "NumeroPrevisto",
            sortable: false,
          },
          {
            text: "Quantità Prodotta",
            value: "NumeroEffettivo",
            sortable: false,
          },
          { text: "Azioni", value: "actions", sortable: false },
          //{ text: "Quantità prodotte", value: "produced_qty", align: "start", sortable: false },
        ];
      }

      if (this.currentViewMode == "columns")
        this.manTypes.forEach((item) => {
          for (let key in item) {
            let inserting = {
              text: item[key],
              priority: null,
              sortable: false,
              filterable: false,
            };
            if (item[key] == "PLOTTER") {
              inserting.priority = 0;
              headers.push(inserting);
            }
            if (item[key] == "PLATINA") {
              inserting.priority = 1;
              headers.push(inserting);
            }
            if (item[key] == "AUTOPLATINA") {
              inserting.priority = 2;
              headers.push(inserting);
            }
            if (item[key] == "LAVORAZIONI MANUALI") {
              inserting.priority = 3;
              headers.push(inserting);
            }
          }
        });

      headers.sort((a, b) => a.priority - b.priority);
      return headers;
    },
    filteredProductions() {
      return this.productions.filter((item) => item.queue == 1);
    },

    user() {
      return this.$store.getters.getCurrentUser;
    },
    completedProds() {
      return this.productions.filter(
        (item) => item.queue == 0 && item.state == 3
      );
    },
    queueProds: {
      get() {
        return this.productions.filter((item) => item.queue == 1);
      },
      set(value) {
        this.$emit("update:items", value);
      },
    },
    waitingProds: {
      get() {
        return this.productions.filter(
          (item) => !item.queue && item.state != 3 && item._id
        );
      },
      set(value) {
        this.$emit("update:items", value);
      },
    },
    registeredProds: {
      get() {
        return this.productions.filter((item) => !item._id);
      },
    },
    productions: {
      get() {
        return this.projects;
      },
    },
  },
  destroyed() {
    clearInterval(this.polling);
  },
  methods: {
    computeFilteredManufactures() {
      const items = {};
      // Itera tutte le produzioni filtrate
      this.filteredProductions.forEach((production) => {
        Object.keys(production.manufactures).forEach((type) => {
          if (!items[type]) items[type] = [];
          items[type].push({
            ...production,
            specificManufacture: production.manufactures[type],
          });
        });
      });

      // Analizza l'ordine delle produzioni per ogni tipo
      const parsedOrder = {};
      Object.keys(this.manufactureOrder).forEach((type) => {
        if (this.manufactureOrder[type]) {
          try {
            parsedOrder[type] = JSON.parse(this.manufactureOrder[type]);
          } catch (error) {
            console.error("JSON parsing error for type " + type, error);
            parsedOrder[type] = [];
          }
        } else {
          parsedOrder[type] = [];
        }
      });

      // Ordina gli elementi per ogni tipo in base all'ordine definito in manufactureOrder
      Object.keys(items).forEach((type) => {
        if (!parsedOrder[type]) {
          parsedOrder[type] = []; // Assicura che l'array esista sempre
        }
        items[type].sort((a, b) => {
          let indexA = parsedOrder[type].indexOf(a.id_prod);
          let indexB = parsedOrder[type].indexOf(b.id_prod);
          indexA = indexA === -1 ? Infinity : indexA;
          indexB = indexB === -1 ? Infinity : indexB;
          return indexA - indexB;
        });
      });

      return items;
    },

    updateQueue() {
      this.getProjects().then(() => {});
    },
    mapGroupHead(group) {
      if (group == "queue")
        return { text: "Coda di produzione", color: "#c61a09" };
      if (group == "waiting") return { text: "In attesa", color: "#ffa500" };
      if (group == "registered")
        return { text: "Registrate", color: "#a9a9a9" };
      if (group == "completed")
        return { text: "Produzione completata", color: "#25a244" };
    },
    mapGroup(item) {
      if (item == "queue") return this.modifiedQueueProds;
      if (item == "waiting") return this.waitingProds;
      if (item == "registered") return this.registeredProds;
      if (item == "completed") return this.completedProds;
    },
    setViewMode() {
      this.currentViewMode =
        this.currentViewMode == "list" ? "columns" : "list";
    },
    handleDragEnd(event) {
      let oldIndex = event.oldIndex;
      let newIndex = event.newIndex;

      if (
        oldIndex < 0 ||
        newIndex < 0 ||
        oldIndex >= this.queueProds.length ||
        newIndex >= this.queueProds.length
      ) {
        console.error("Invalid indices", oldIndex, newIndex);
        return;
      }

      const movedElement = this.queueProds[oldIndex];
      if (!movedElement) {
        console.error("No element found at oldIndex", oldIndex);
        return;
      }

      // Remove the element from the old position and insert it at the new position

      axios
        .post("stock-productions/stock-sort", {
          old: { _id: movedElement._id, ordProd: movedElement.id_prod },
          new: {
            _id: this.queueProds[newIndex]._id,
            ordProd: this.queueProds[newIndex].id_prod,
          },
        })
        .then((res) => {
          this.getProjects()
            .then(() => {})
            .catch((err) => {
              console.error("Error fetching updated list", err);
            });
        })
        .catch((err) => {
          console.error("Error during sorting", err);
        })
        .finally(() => {
          this.sortLoading = false;
          this.dragging = false;
        });
    },
    onEndDrag(event, type) {
      if (event.oldIndex !== event.newIndex) {
        let newArr = [...this.filteredManufactures[type]];
        const movedItem = newArr.splice(event.oldIndex, 1)[0];
        newArr.splice(event.newIndex, 0, movedItem);

        this.$set(this.filteredManufactures, type, newArr);

        this.$nextTick(() => {});

        this.updateManufactureOrderOnServer(
          newArr.map((item) => item.id_prod),
          type
        );
      }
    },
    updateManufactureOrderOnServer(newOrder, type) {
      axios
        .post(
          "update-manufacture-order",
          { manType: type, newOrder },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {})
        .catch((error) => {
          console.error("Error updating order:", error);
        })
        .finally(() => {
          this.dragging = false;
        });
    },
    filterProjectByColumn(manType) {
      let items = [];
      if (manType == "GENERICA") manType = "generic";
      this.filteredProductions.forEach((item) => {
        for (let man in item.manufactures) {
          if (man == manType) items.push(item);
        }
      });
      items.sort((a, b) => a.id - b.id);
      return items;
    },
    checkMove(e) {
      return this.dragging;
    },
    getManTypes(manufactures) {
      const priorityMap = {
        PLOTTER: 0,
        PLATINA: 1,
        AUTOPLATINA: 2,
        "LAVORAZIONI MANUALI": 3,
      };

      let manTypes = [];

      // Creazione di un array temporaneo per le chiavi con priorità
      let prioritizedKeys = [];

      for (let key in manufactures) {
        // Se la chiave ha una priorità, inseriscila nell'array temporaneo
        if (Object.prototype.hasOwnProperty.call(priorityMap, key)) {
          prioritizedKeys.push({
            key: key,
            priority: priorityMap[key],
          });
        } else {
          // Altrimenti, gestisci come prima
          if (key != "generic") manTypes.push(key);
          else manTypes.push("GENERICA");
        }
      }

      // Ordinamento dell'array temporaneo in base alla priorità
      prioritizedKeys.sort((a, b) => a.priority - b.priority);

      // Aggiunta delle chiavi ordinate in manTypes
      for (let item of prioritizedKeys) {
        manTypes.push(item.key);
      }

      return manTypes;
    },
    calcProduced(item) {
      let produced = 0;

      // Controlla se item.manufactures è definito e non vuoto
      if (item.manufactures && Object.keys(item.manufactures).length > 0) {
        let historic =
          item.manufactures[
            Object.keys(item.manufactures)[
              Object.keys(item.manufactures).length - 1
            ]
          ];

        // Controlla se historic è un array prima di iterare
        if (Array.isArray(historic)) {
          historic.forEach((prod) => {
            if (prod.produced) produced = Number(produced) + Number(prod.produced);
          });
        }
      }

      return produced;
    },
    unDragHeaders() {
      let elements = document.querySelectorAll(".v-row-group__header");
      elements.forEach((el) => {
        el.classList.add("ignore-drag");
      });
    },
    getItem(e) {
      return e.queue ? "draggable" : "ignore-drag queue";
    },
    openResponseBoardModal(data, hasResponse) {
      if ("response" in data.boardData && data.boardData.response != null) {
        data.boardData.response = JSON.parse(data.boardData.response);
        data.boardData.hasResponse = true;
      }
      this.uploadResponseData = data;
      this.isResponseBoardModal = true;
    },
    openUploadBoardModal(data) {
      this.uploadBoardData = data;
      this.isUploadBoardModal = true;
    },
    openManufactureModal(data) {
      this.manufactureData = data;
      this.isManufactureModalVisible = true;
    },
    formatDate(date) {
      date = new Date(date);
      return (
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
    },
    saveSearch() {
      localStorage.setItem("production_search", this.search);
    },
    async getProjects() {
      if (!this.dragging) {
        let params = {
          search: this.search,
          page: this.page,
          dateSort: this.dateSort,
        };

        await axios.get("stock-productions", { params }).then((response) => {
          this.manufactureOrder = response.data.manufactureOrder;
          this.projects = response.data.productions;
          this.modifiedQueueProds = [...this.queueProds];
          this.totalCount = response.data.total;
          this.manTypes = response.data.manTypes;
          this.loading = false;
          this.manufactureItems = this.computeFilteredManufactures();
          return;
        });
      }
    },
    goTo(a) {
      if (event.target.classList.contains("btn__content")) return;
      this.$router.push("/to-production/" + a.id_project).catch(() => {});
    },
    getCurrentStatus(item) {
      if (item.manufactures) {
        if (item.state == 0) {
          return { color: "#c0c0c0", text: "In attesa" };
        } else if (item.state == 1) {
          return { color: "red", text: "In corso" };
        } else if (item.state == 2) {
          return { color: "#ffc412", text: "Da completare" };
        } else if (item.state == 3) {
          return { color: "green", text: "Completato" };
        } else if (item.state == 4) {
          return { color: "#ffc412", text: "In sospeso" };
        }
      } else {
        if (item.Stato == 0) {
          return { color: "#c0c0c0", text: "Registrato" };
        } else if (item.Stato == 1) {
          return { color: "#ffc412", text: "Rilasciato" };
        } else if (item.Stato == 2) {
          return { color: "#red", text: "In corso" };
        } else if (item.Stato == 3) {
          return { color: "green", text: "Completato" };
        }
      }
    },
    tomorrow(delivery) {
      var today = new Date();
      var delivery_date = new Date(delivery);
      var delay = this.findDayDifference(today, delivery_date);

      if (delivery) {
        var formatted_date = delivery_date
          .toLocaleString()
          .split(",")[0]
          .replaceAll("/", "-");
        if (delay == -1) {
          return {
            date: formatted_date,
            isExpiring: 1,
            text: "In scadenza oggi",
          };
        } else if (delay == 1) {
          return {
            date: formatted_date,
            isExpiring: 1,
            text: "In scadenza domani",
          };
        } else if (delay < -1) {
          return { date: formatted_date, isExpiring: 1, text: "Scaduto" };
        } else {
          return { date: formatted_date, isExpiring: 0 };
        }
      }
    },
    findDayDifference(date1, date2) {
      return Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
    },
  },
};
</script>

<style scoped>
.expiring {
  background-color: tomato;
  color: white;
  position: absolute;
  left: 80px;
  width: max-content;
  padding: 1px 3px;
  border-radius: 2px;
  font-size: 11px;
}

.capitalizer {
  text-transform: capitalize;
}

.full-height {
  height: 100%;
}

.elevation-1 {
  width: 100%;
  box-sizing: border-box;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 48.5%;
}

.v-data-footer__select {
  display: none !important;
}

.action-tooltip {
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action {
  z-index: 2000;
}

.block-action:hover .action-tooltip {
  visibility: visible;
}

.text-center {
  text-align: center;
}

.force-square {
  border-radius: 0px !important;
}

.bigger-badge {
  font-size: 10px !important;
}

.dt {
  width: 100%;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
</style>
